import { RootState } from '@stur/store/root-reducer';
import { GenericAsyncThunk } from '@stur/store/store';

import { ThunkStatus } from './action-status-state';

function hasActionStatus<T extends GenericAsyncThunk>(status: ThunkStatus) {
  return (thunk: T | T[]) => (state: RootState): boolean => {
    const thunkArray: T[] = Array.isArray(thunk) ? thunk : [thunk];
    return thunkArray.some((t) => state.actionStatus.actions[t.typePrefix] === status);
  };
}

const getActionStatus = (typePrefix: string) => (state: RootState): ThunkStatus | undefined =>
  state.actionStatus.actions[typePrefix];

const isRequestPending = <T extends GenericAsyncThunk>(requestId: string, thunk: T) => (
  state: RootState
): boolean => {
  const inFlight = state.actionStatus.inFlight[thunk.typePrefix];
  return !!(inFlight && inFlight[requestId]);
};

const isActionPending = hasActionStatus('pending');
const isActionRejected = hasActionStatus('rejected');
const isActionFulfilled = hasActionStatus('fulfilled');

export const ActionStatusSelectors = {
  getActionStatus,
  isRequestPending,
  isActionPending,
  isActionRejected,
  isActionFulfilled,
} as const;
