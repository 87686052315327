import { ErrorMessage, FormikValues } from 'formik';
import React, { ReactElement } from 'react';

import { ServerValidationErrorMapping } from '@stur/errors/server-validation-error';

export interface FormErrorProps<T extends FormikValues> {
  name: keyof T;
  serverErrors?: Array<ServerValidationErrorMapping<T>>;
}

export const FormError = <T extends FormikValues>(props: FormErrorProps<T>): ReactElement => {
  const { name, serverErrors } = props;

  const serverError = serverErrors?.find((error) => error.field === name);
  if (serverError?.message) {
    return <small className="form-error is-visible">{serverError.message}</small>;
  }

  return <ErrorMessage className="form-error is-visible" name={name as string} component="small" />;
};
